import { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { useNavigate } from "react-router";
import { cancelApplication } from "../../services/AdminDashService";
import { Application } from "../../types/Application";
import { Applicant } from "../../types/Applicant";

interface Props {
  selectedApplicant: Applicant | undefined;
  selectedApplication: Application;
  onCancel(): any;
  show?: boolean;
  type?: string;
}

const CancelApplicationModal = ({
  selectedApplicant,
  selectedApplication,
  onCancel,
  show,
}: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const notification = useAppSelector((state) => state.applications.notification);
  const [cancelling, setCancelling] = useState<boolean>(false);

  const cancelAction = () => {
    onCancel();
  };

  useEffect(() => {
    if (notification && notification?.origin === "cancelApplication") {
      setCancelling(false);
      if (
        notification &&
        notification.type === "INFO" &&
        notification.message?.endsWith("has been successfully cancelled")
      ) {
        navigate("/AdminDashboard/Applications");
      } else {
        onCancel();
      }
    }
  }, [notification]);

  const cancelApplicationAction = () => {
    setCancelling(true);
    dispatch(
      cancelApplication(
        selectedApplication.applicationId,
        selectedApplication.unitId
      )
    );
  };

  return (
    <Modal aria-labelledby="contained-modal-title-vcenter" centered show={show}>
      <Modal.Header>
        <Modal.Title>
          <span className="cancel-icon">!</span>Cancel Application
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="details">
          <div className="row">
            <div className="col form">
              <label>Application:</label>
            </div>
            <div className="col">
              <div>
                {selectedApplication && selectedApplication.communityName}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col form">
              <label>Primary Applicant:</label>
            </div>
            <div className="col">
              <div>
                {selectedApplicant &&
                  `${selectedApplicant.firstName}${
                    selectedApplicant.middleName
                      ? " " + selectedApplicant.middleName + " "
                      : " "
                  }${selectedApplicant.lastName}`}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={cancelAction}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={cancelApplicationAction}
          disabled={cancelling}
          id="cancel-action"
        >
          {cancelling && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="me-2"
            />
          )}
          {cancelling ? "Cancelling Application" : "Cancel Application"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CancelApplicationModal;
