import { ApplicantScreening } from "src/types/Applicant";
import { convertToLocalAndFormatDate, formatTime } from "./Utils";
import { Issue } from "src/types/Screening";

/**
 * Helper function for sorting dates w/ consideration
 *      over the createdOn and modifiedDate
 */
const sortByDateDesc = (a, b) => {
  const dateA = new Date(a.modifiedDate || a.createdOn);
  const dateB = new Date(b.modifiedDate || b.createdOn);

  if (dateA.getTime() > dateB.getTime()) {
    return -1;
  }
  if (dateA.getTime() < dateB.getTime()) {
    return 1;
  }
  return 0;
};

/**
 * Helper function for exposing the onfido and fadv screening status,
 *  as well as the most recent update dated.
 */

export interface ScreeningInfo {
  lastUpdated: string;
  screeningStatus: string;
  screeningVendor: string;
  issues?: Issue[];
}

interface ScreeningStatuses {
  [key: string]: any;
  onfido?: ScreeningInfo;
  fadv?: ScreeningInfo;
}
export const getScreeningData = (
  [...applicantScreening]: ApplicantScreening[],
  setLatestDate
): ScreeningStatuses | undefined => {
  const hasApplicantScreeningData =
    applicantScreening && applicantScreening.length;

  const statuses: ScreeningStatuses = {};
  let latestDate: string | null = null;

  if (hasApplicantScreeningData) {
    // sort descending by dates
    applicantScreening.sort(sortByDateDesc);

    // since this is sorted in descending order
    // the most updated dates should be available first
    applicantScreening.sort(sortByDateDesc).forEach((screening, idx) => {
      const {
        screeningVendor,
        screeningStatus,
        createdOn,
        modifiedDate,
        isSoftDeleted,
        issues,
      } = screening;

      const isComplete = !!statuses["onfido"] && !!statuses["fadv"];

      // if no vendor or already complete, end fast
      if (!screeningVendor || isComplete || isSoftDeleted) return;

      // capture latest date
      if (idx === 0) {
        latestDate = modifiedDate || createdOn;
      }

      // format
      const vendor = screeningVendor.toLowerCase();
      statuses[vendor] = {
        lastUpdated: modifiedDate || createdOn,
        screeningStatus,
        screeningVendor,
        issues,
      };
    });

    statuses["latestDate"] = latestDate;
    if (latestDate) {
      const formattedDate = `${formatTime(
        latestDate
      )}, ${convertToLocalAndFormatDate(latestDate)}`;
      setLatestDate(formattedDate);
    }
    return statuses;
  }
};
