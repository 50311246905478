// NATIVE IMPORTS
require("./ApplicationDetails.scss");
import { useParams } from "react-router";
import { useCallback, useEffect, useRef, useState } from "react";

// SERVICES
import LocalStorageService from "../../services/LocalStorageService";
import { getApplicationById } from "../../services/AdminDashService";
import { getHelpRequestsBulk } from "../../services/HelpRequestService";
import { setHelpRequests } from "src/store/features/applications/applicationsSlice";

// COMPONENTS
import Applicants from "../ApplicationDetails/Applicants";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";
import Screening from "../ApplicationDetails/Screening";
import LeaseProcess from "../ApplicationDetails/LeaseProcess";
import LeftPanel from "../ApplicationDetails/LeftPanel";
import RightPanel from "../ApplicationDetails/RightPanel";
import CompleteApplicationModal from "../ApplicationDetails/CompleteApplicationModal";
import RedFlagIcon from "../Shared/icons/RedFlagIcon";
import InformationIcon from "../Shared/icons/InformationIcon";
import HelpRequestModal from "../AdminDashboard/HelpRequestModal";

// UTILITIES
import { capitalize, formatUnit } from "../../utils/Utils";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { resetNotification } from "src/store/features/applications/applicationsSlice";
import { Alert } from "react-bootstrap";
import WarningModal from "../Shared/WarningModal";
import { ApplicationStatusText, ReduxApplication } from "src/types/Application";
import LastFetchedDate from "../Shared/LastFetchedDate";
import { getConfigs } from "src/configs/Config";

export interface IApplicant {
  applicantTypeId: number;
}

const ApplicationDetails = () => {
  const { refetchDataIntervalTime } = getConfigs();

  const { id } = useParams();
  const helpRequests = useAppSelector(
    (state) => state.applications.helpRequests
  );
  const applicationFromState = useAppSelector(
    (state) => state.applications.application
  );
  const notification = useAppSelector(
    (state) => state.applications.notification
  );
  let [email, setEmail] = useState();
  const [applicationRequests, setApplicationRequests]: any = useState([]);
  const [application, setApplication] = useState<ReduxApplication | undefined>(
    undefined
  );
  const [showRequest, setShowRequest] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const refetchTimerId = useRef<undefined | NodeJS.Timer>(undefined);
  const {
    application: applicationFromLocal,
    helpRequests: helpRequestsFromLocal,
  } = LocalStorageService.getApplication(id);
  const lastFetchedDate =
    application?.lastFetchedDate ?? applicationFromLocal?.lastFetchedDate ?? "";

  const dispatch = useAppDispatch();

  const transferHelpRequestToState = (helpRequestsFromLocal) => {
    if (!helpRequestsFromLocal || !helpRequestsFromLocal.length) {
      dispatch(
        setHelpRequests({
          helpRequests: [],
        })
      );
      return;
    }

    dispatch(
      setHelpRequests({
        helpRequests: helpRequestsFromLocal[0].helpRequestList,
      })
    );
  };

  const getHelpRequest = () => {
    return helpRequests.filter((r) => r.applicationId + "" === id);
  };

  const updateLocalStorage = () => {
    const helpRequests = getHelpRequest();
    LocalStorageService.setApplicationInLocal(
      applicationFromLocal,
      helpRequests
    );
  };

  useEffect(() => {
    if (Object.keys(applicationFromState).length != 0) {
      setApplication(applicationFromState as ReduxApplication);
      return;
    }
  }, [JSON.stringify(applicationFromState)]);

  // Initialize application data
  useEffect(() => {
    dispatch(getApplicationById(id));
    // @ts-expect-error
    dispatch(getHelpRequestsBulk([id]));

    transferHelpRequestToState(helpRequestsFromLocal);

    if (!!applicationFromLocal && !application) {
      setApplication(applicationFromLocal);
    }
  }, []);

  const refreshApplicationData = useCallback(() => {
    if (id) {
      dispatch(getApplicationById(id));
      dispatch(getHelpRequestsBulk([id]));
    }
  }, [id]);

  // Refetch data when window is on focus
  useEffect(() => {
    const inactiveStatuses = [
      ApplicationStatusText.REJECTED,
      ApplicationStatusText.COMPLETED,
      ApplicationStatusText.REMOVED,
      ApplicationStatusText.CANCELLED,
      ApplicationStatusText.CLOSED,
      ApplicationStatusText.APPLICATION_COMPLETE,
    ];
    if (
      typeof application?.applicationStatus === "undefined" ||
      inactiveStatuses.includes(application?.applicationStatus)
    ) {
      return;
    }

    const initRefetchTimer = () => {
      if (refetchTimerId.current) clearInterval(refetchTimerId.current);
      refetchTimerId.current = setInterval(
        refreshApplicationData,
        refetchDataIntervalTime
      );
    };

    const stopRefetchTimer = () => {
      if (refetchTimerId.current) {
        clearInterval(refetchTimerId.current);
        refetchTimerId.current = undefined;
      }
    };

    const visibilitychange = () => {
      if (document.hidden) {
        stopRefetchTimer();
      } else {
        initRefetchTimer();
      }
    };

    initRefetchTimer();
    document.addEventListener("visibilitychange", visibilitychange);
    window.addEventListener("focus", initRefetchTimer);
    window.addEventListener("blur", stopRefetchTimer);

    return () => {
      stopRefetchTimer();
      document.removeEventListener("visibilitychange", visibilitychange);
      window.removeEventListener("focus", initRefetchTimer);
      window.removeEventListener("blur", stopRefetchTimer);
    };
  }, [refreshApplicationData, application?.applicationStatus]);

  useEffect(() => {
    window.localStorage.setItem("TAB_OPENED", "");
    window.localStorage.removeItem("TAB_OPENED");

    function storageEvent(event) {
      if (!event.newValue) return;
      if (event.key === "SENDING_CREDENTIALS" && event.newValue) {
        const data = JSON.parse(event.newValue);
        window.sessionStorage.setItem("email", data.email);
        window.sessionStorage.setItem("token", data.token);
        setEmail(data.email);
      }
    }
    window.removeEventListener("storage", storageEvent);
    window.addEventListener("storage", storageEvent);
  }, [application]);

  useEffect(() => {
    loadHelpRequests();
    updateLocalStorage();
  }, [helpRequests]);

  const loadHelpRequests = () => {
    const filterData = getHelpRequest();
    setApplicationRequests(
      filterData[0]?.helpRequestList
        .filter((f) => {
          return f.helpStatus.toLowerCase() === "new";
        })
        .sort((a, b) => {
          const c =
            (a.modifiedDate && new Date(a.modifiedDate)) ||
            (a.createdDate && new Date(a.createdDate));
          const d =
            (b.modifiedDate && new Date(b.modifiedDate)) ||
            (b.createdDate && new Date(b.createdDate));
          return d - c;
        })
    );
  };

  useEffect(() => {}, [applicationRequests]);

  const showRequestModal = (request) => {
    setSelectedRequest(request);
    setShowRequest(true);
  };

  const closeNotification = () => {
    dispatch(resetNotification());
  };

  const getPrimaryApplicant = () => {
    return (
      application && application.applicants.find((x) => x.applicantTypeId == 1)
    );
  };

  const closeReadyToFinishNotification = (e) => {
    if (e) {
      e.stopPropagation();
      setShowConfirmationModal(false);
    }
  };

  return (
    <div id="avb-application-details">
      <div className="admin-header">
        <Header email={email} />
      </div>
      {application && application.unitId && (
        <div className="admin-body container-fluid">
          <div className="heading admin-body_title d-flex justify-content-between flex-wrap align-items-center">
            <div>
              Application Details: Unit {formatUnit(application.unitId)}{" "}
              {capitalize(application.communityName)}
            </div>
            <div>
              <LastFetchedDate
                date={lastFetchedDate}
                onRefresh={refreshApplicationData}
              />
            </div>
          </div>
          {notification && notification.type === "INFO" && (
            <Alert variant="success" onClose={closeNotification} dismissible>
              {notification.message}
            </Alert>
          )}
          {applicationRequests && applicationRequests.length > 0 && (
            <div className="help-requests">
              <ul>
                {applicationRequests?.map((r) => {
                  return (
                    <li key={r.id}>
                      <RedFlagIcon className="request-flag-icon" />
                      <div className="request-topic">
                        <b>HELP REQUEST</b>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <span className="topic">{r.helpRequestTopic}</span>
                      </div>

                      <button
                        className="resolve"
                        onClick={() => showRequestModal(r)}
                      >
                        Resolve
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          {(application?.readyToFinishCount ?? 0) > 0 && (
            <div className="ready-to-finish-notification">
              <InformationIcon className="information-icon" />
              <div className="msg">
                <b>THIS APPLICATION IS COMPLETE AND READY TO FINISH</b>
              </div>
              <button
                className="finish-application"
                onClick={() => setShowConfirmationModal(true)}
              >
                Finish Application
              </button>
            </div>
          )}
          <div className="row sections">
            <LeftPanel
              application={application}
              refreshApplicationData={() => {
                refreshApplicationData();
              }}
            />
            <RightPanel>
              <Applicants application={application} />
              <Screening application={application} />
              <LeaseProcess application={application} />
            </RightPanel>
          </div>
          <Footer />
          <WarningModal />
          <HelpRequestModal
            show={showRequest}
            onCancel={() => setShowRequest(false)}
            request={selectedRequest}
            applicant={application}
          />
          <CompleteApplicationModal
            selectedApplicant={getPrimaryApplicant()}
            selectedApplication={application}
            onCancel={closeReadyToFinishNotification}
            showModal={showConfirmationModal}
            setShowModal={setShowConfirmationModal}
          />
        </div>
      )}
    </div>
  );
};

export default ApplicationDetails;
