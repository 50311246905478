export enum ScreeningStatus {
  Approved = 1,
  ApprovedWithConditions,
  Declined,
  FurtherReview,
  Pending,
  GuarantorRequired,
  DocumentSubmissionCompleted,
  Retry,
  ProblemResolution,
  ApprovedWithConditionsMet,
}

export interface Issue {
  id: number;
  applicantScreeningId: number;
  type: string;
  description: string;
}

export const ScreeningTypes = {
  ssn: "SSN",
  address: "Address",
};
